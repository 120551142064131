<template>
  <div>
    <view-container :viewHeader="'Employee Management'">

      <add-employee-dialog
        v-if="dialogs.isAddEmployeeDialogActive"
        :is-dialog-open="dialogs.isAddEmployeeDialogActive"
        @add-employee="addEmployee"
        @show-snackbar="emitSnackbarEvent"
        @refresh-employees="refreshEmployees"
        @update:is-dialog-open="dialogs.isAddEmployeeDialogActive = $event">
      </add-employee-dialog>

      <archive-employee-dialog
        v-if="dialogs.isArchiveEmployeeDialogActive"
        :is-dialog-open="dialogs.isArchiveEmployeeDialogActive"
        :targetEmployee="selectedEmployee"
        @show-snackbar="emitSnackbarEvent"
        @refresh-employees="refreshEmployees"
        @update:is-dialog-open="dialogs.isArchiveEmployeeDialogActive = $event">
      </archive-employee-dialog>

      <add-employee-title-dialog
        v-if="dialogs.isAddEmployeeTitleDialogActive"
        :is-dialog-open="dialogs.isAddEmployeeTitleDialogActive"
        @add-title="addTitle"
        @show-snackbar="emitSnackbarEvent"
        @refresh-employees="refreshEmployees"
        @update:is-dialog-open="dialogs.isAddEmployeeTitleDialogActive = $event">
      </add-employee-title-dialog>

      <edit-employee-dialog
        v-if="dialogs.isEditEmployeeDialogActive"
        :targetEmployee="selectedEmployee"
        :is-dialog-open="dialogs.isEditEmployeeDialogActive"
        @show-snackbar="emitSnackbarEvent"
        @edit-employee="editEmployee"
        @refresh-employees="refreshEmployees"
        @update:is-dialog-open="dialogs.isEditEmployeeDialogActive = $event">
      </edit-employee-dialog>

      <v-card border="gray thin" elevation="0" min-width="200" class="rounded-sm">
        <template v-slot:text v-if="this.$vuetify.display.mobile">
          <v-row dense>
            <v-col>
              <v-text-field
                clearable
                v-model="search"
                label="Search Employees"
                prepend-inner-icon="mdi-magnify"
                variant="outlined"
                hide-details
                single-line
                density="compact"
              ></v-text-field>
            </v-col>

            <v-col cols="auto">
              <v-btn @click="refreshBtnClicked" block size="x-small" height="40" class="px-4" color="secondary" variant="tonal">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="4">
              <v-btn block @click="dialogs.isAddEmployeeDialogActive = true" prepend-icon="mdi-plus" size="x-small" height="40" class="px-4" color="primary" variant="tonal">
                New Employee
              </v-btn>
            </v-col>

            <v-col cols="4">
              <v-btn block prepend-icon="mdi-filter" size="x-small" height="40" class="px-4" @click="toggleAdvancedFilterMenu" color="secondary" variant="tonal">
                Filter
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <template v-slot:text v-else>
          <v-row dense>
            <v-col cols="auto">
              <v-btn block @click="dialogs.isAddEmployeeDialogActive = true" prepend-icon="mdi-plus" size="x-small" height="40" class="px-4" color="primary" variant="tonal">
                New Employee
              </v-btn>
            </v-col>

            <v-col cols="auto">
              <v-btn block prepend-icon="mdi-plus" size="x-small" height="40" class="px-4" @click="dialogs.isAddEmployeeTitleDialogActive = true" color="primary" variant="tonal">
                New Title
              </v-btn>
            </v-col>

            <v-col cols="auto">
              <v-btn @click="getEmployeesWithTimeout" block prepend-icon="mdi-refresh" size="x-small" height="40" class="px-4" color="secondary" variant="tonal">
                Refresh
              </v-btn>
            </v-col>

            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-menu
                max-width="430"
                v-model="advancedFilterMenu"
                :close-on-content-click="false"
                location="bottom"
              >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" block prepend-icon="mdi-filter" size="x-small" height="40" :class="activeFilterCount ? 'pl-4 pr-2 px-4' : 'px-4'" color="secondary" variant="tonal">
                    Advanced Filters

                    <template v-slot:append>
                      <v-badge v-if="activeFilterCount" :content="activeFilterCount" inline color="secondary"></v-badge>
                    </template> 

                  </v-btn>
                </template>

                <v-card min-width="300">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="pt-3">
                        <p><strong>Advanced Filters</strong></p>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field v-model="filters.name" prepend-inner-icon="mdi-account" variant="outlined" density="compact" label="Employee Name"></v-text-field>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-select
                          :items="shifts"
                          v-model="filters.shift"
                          item-title="title"
                          item-value="key"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-clock-outline"
                          label="Shift"
                          clearable
                        ></v-select>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-select
                          :items="employeeDepartments"
                          v-model="filters.department"
                          item-title="department"
                          item-value="employee_Department_ID"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-account-details-outline" 
                          label="Department"
                          clearable
                        ></v-select>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-select
                          :items="employeeTitles"
                          v-model="filters.title"
                          item-title="title"
                          item-value="employee_Title_ID"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-briefcase-outline"
                          label="Title"
                          clearable
                        ></v-select>
                      </v-col>

                      <v-col cols="12" class="pt-3">
                        <p><strong>Date Hired Between</strong></p>
                      </v-col>

                      <v-col cols="6" class="py-0 pr-1">
                        <v-text-field v-model="filters.startDate" prepend-inner-icon="mdi-calendar" variant="outlined" density="compact" label="Start Date" type="date"></v-text-field>
                      </v-col>

                      <v-col cols="6" class="py-0 pl-1">
                        <v-text-field v-model="filters.endDate" prepend-inner-icon="mdi-calendar" variant="outlined" density="compact" label="End Date" type="date"></v-text-field>
                      </v-col>

                    </v-row>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn variant="text" @click="resetFilters">Reset</v-btn>
                    <v-btn color="primary" variant="tonal" @click="applyFilters">Apply</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>

            <v-col cols="4">
              <v-text-field
                clearable
                v-model="search"
                label="Search"
                prepend-inner-icon="mdi-magnify"
                variant="outlined"
                hide-details
                single-line
                density="compact"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>


        <v-data-table
          :sort-by="sortBy"
          :search="search"
          :headers="headers"
          :items="filteredEmployees"
          :mobile="this.$vuetify.display.mobile" mobile-breakpoint="sm"
        >

          <template v-slot:[`item.shift`]="{ item }">
            <v-tooltip text="9:00am - 5:00pm">
              <template v-slot:activator="{ props }">
                <v-chip v-bind="props" v-if="item.shift === 1" label color="primary" prepend-icon="mdi-weather-sunset-up">
                  1st Shift
                </v-chip>
              </template>
            </v-tooltip>  

            <v-tooltip text="1:00pm - 9:00pm">
              <template v-slot:activator="{ props }">
                <v-chip v-bind="props"  v-if="item.shift === 2" label color="primary" prepend-icon="mdi-weather-sunny">
                  2nd Shift
                </v-chip>
              </template> 
            </v-tooltip>  

            <v-tooltip text="9:00pm - 5:00am">
              <template v-slot:activator="{ props }">
                <v-chip v-bind="props"  v-if="item.shift === 3" label color="primary" prepend-icon="mdi-weather-night">
                  3rd Shift
                </v-chip>
              </template> 
            </v-tooltip>  
          </template>

          <template v-slot:[`item.department`]="{ item }">
            {{ item.department || 'No Department' }}
          </template>

          <template v-slot:[`item.title`]="{ item }">
            {{ item.title || 'No Title' }}
          </template>

          <template v-slot:[`item.hire_Date`]="{ item }">
            {{ formatDate(item.hire_Date) || 'Unknown Date' }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip text="Edit Employee">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" class="mx-1" variant="plain" density="compact" icon="mdi-pencil" @click="deleteEmployee(item)"></v-btn>
              </template>
            </v-tooltip>
            <v-tooltip text="Archive Employee">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" class="mx-1" variant="plain" density="compact" icon="mdi-archive-outline" @click="toggleArchiveEmployeeDialog(item)"></v-btn>
              </template>
            </v-tooltip>
          </template>

          <template v-slot:no-data>
            <div>No employees were found...</div>
          </template>
        </v-data-table>
      </v-card>
    </view-container>
  </div>
</template>

<script>
import api from '@/services/api'
import eventBus from '@/services/eventBus';
import ViewContainer from '../layouts/ViewContainer'
import AddEmployeeDialog from '@/components/dialogs/AddEmployeeDialog.vue';
import AddEmployeeTitleDialog from '@/components/dialogs/AddEmployeeTitleDialog.vue';
import ArchiveEmployeeDialog from '@/components/dialogs/ArchiveEmployeeDialog.vue';

export default {
  name: 'EmployeesView',
  components: {
    ViewContainer,
    AddEmployeeDialog,
    AddEmployeeTitleDialog,
    ArchiveEmployeeDialog
  },
  data: () => ({
    advancedFilterMenu: false,
    employees: [],
    employeeTitles: [],
    employeeDepartments: [],
    selectedEmployee: null,
    search: '',
    sortBy: [{ key: 'full_Name', order: 'asc' }],
    isEmployeesDatagridLoading: true,
    dialogs: {
      isAddEmployeeDialogActive: false,
      isEditEmployeeDialogActive: false,
      isAddEmployeeTitleDialogActive: false,
      isArchiveEmployeeDialogActive: false, 
    },
    shifts: [
      { title: '1st Shift', key: '1' },
      { title: '2nd Shift', key: '2' },
      { title: '3rd Shift', key: '3' },
    ],
    headers: [
      { title: 'ID', key: 'employee_ID' },
      { title: 'Name', key: 'full_Name' },
      { title: 'Department', key: 'department' },
      { title: 'Title', key: 'title' },
      { title: 'Shift', key: 'shift' },
      { title: 'Date Hired', key: 'hire_Date' },
      { title: 'Actions', key: 'actions' }
    ],
    filters: {
      name: '',
      shift: '',
      department: '',
      title: '',
      startDate: '',
      endDate: '',
    },
  }),
  computed: {
    filteredEmployees() {
      return this.employees.filter(employee => {
        const matchesName = this.filters.name ? employee.full_Name?.toLowerCase().includes(this.filters.name.toLowerCase()) : true;
        const matchesShift = this.filters.shift ? employee.shift.toString() === this.filters.shift.toString() : true;
        const matchesDepartment = this.filters.department ? employee.department_ID === this.filters.department : true;
        const matchesTitle = this.filters.title ? employee.title_ID === this.filters.title : true;
        const matchesStartDate = this.filters.startDate ? new Date(employee.hire_Date) >= new Date(this.filters.startDate) : true;
        const matchesEndDate = this.filters.endDate ? new Date(employee.hire_Date) <= new Date(this.filters.endDate) : true;
        return matchesName && matchesShift && matchesDepartment && matchesTitle && matchesStartDate && matchesEndDate;
      });
    },
    activeFilterCount() {
      return Object.values(this.filters).filter(value => value).length;
    }
  },
  async mounted() {
    await this.getEmployees();
    await this.getEmployeeDepartments();
    await this.getEmployeeTitles();
  },
  methods: {
    emitSnackbarEvent(payload) {
      eventBus.emit('show-snackbar', payload);
    },
    showSnackbar(color, icon, title, description) {
      this.emitSnackbarEvent({ color, icon, title, description });
    },
    async getEmployees() {
      this.isEmployeesDatagridLoading = true;
      try {
        const response = await api.get('/Employee/GetEmployees');
        if (response.status === 200) {
          this.employees = response.data;
          this.isEmployeesDatagridLoading = false;
          return true;
        } else {
          console.log('Error fetching employees.');
          this.isEmployeesDatagridLoading = false;
          return false;
        }
      } catch (error) {
        console.error('API error:', error);
        this.isEmployeesDatagridLoading = false;
        return false;
      }
    },
    async getEmployeeTitles() {
      try {
        const response = await api.get('/Employee/GetEmployeeTitles');
        if (response.status === 200) {
          this.employeeTitles = response.data;
          return true;
        } else {
          console.log('Error fetching employee titles.');
          return false;
        }
      } catch (error) {
        console.error('API error:', error);
        return false;
      }
    },
    async getEmployeeDepartments() {
      try {
        const response = await api.get('/Employee/GetEmployeeDepartments');
        if (response.status === 200) {
          this.employeeDepartments = response.data;
          return true;
        } else {
          console.log('Error fetching employee departments.');
          return false;
        }
      } catch (error) {
        console.error('API error:', error);
        return false;
      }
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString('en-US', options);
    },
    async refreshEmployees() {
      return this.getEmployeesWithTimeout(true);
    },
    async getEmployeesWithTimeout(showNotification = false) {
      this.isEmployeesDatagridLoading = true;
      console.log("hit")
      try {
        const success = await this.getEmployees();
        if (success && showNotification) {
          this.showSnackbar('success', 'mdi-check-outline', 'Employees were Refreshed');
        }
      } finally {
        this.isEmployeesDatagridLoading = false;
      }
    },
    toggleAddEmployeeDialog() {
      this.dialogs.isAddEmployeeDialogActive = !this.dialogs.isAddEmployeeDialogActive;
    },
    toggleAddEmployeeTitleDialog() {
      this.dialogs.isAddEmployeeTitleDialogActive = !this.dialogs.isAddEmployeeTitleDialogActive;
    },
    toggleArchiveEmployeeDialog(employee) {
      this.selectedEmployee = employee;
      this.dialogs.isArchiveEmployeeDialogActive = !this.dialogs.isArchiveEmployeeDialogActive;
    },
    toggleEditEmployeeDialog(employee) {
      this.selectedEmployee = employee;
      this.dialogs.isEditEmployeeDialogActive = !this.dialogs.isEditEmployeeDialogActive;
    },
    deleteEmployee(employee) {
      // Implement delete functionality here
      console.log('Delete employee:', employee);
    },
    applyFilters() {
      this.advancedFilterMenu = false;
    },
    resetFilters() {
      this.filters.name = '';
      this.filters.shift = '';
      this.filters.department = '';
      this.filters.title = '';
      this.filters.startDate = '';
      this.filters.endDate = '';
    }
  }
};
</script>

<style scoped>
.full-background {
  min-height: 100vh;
}
.text-red {
  color: red;
}
.text-gray {
  color: rgb(180, 180, 180);
}
</style>
