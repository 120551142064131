<template>
  <DialogBase :isDialogVisible="dialogOpen">
    <template v-slot:header>
      <span>{{targetTask.type}}</span>
    </template>

    <template v-slot:actions>
      <v-chip 
      class="ma-1 mr-2" 
      variant="tonal" 
      color="primary"
      label>
        <v-icon icon="mdi-label-outline" start></v-icon>
        {{ targetTask.contact_Task_ID }}
      </v-chip>
    </template>

    <template v-slot:content>
      <v-row>
        <v-col cols="12" class="pt-4 pb-0">
          <p><strong>Task Information</strong></p>
        </v-col>

        <v-col cols="12">
          <v-card class="py-4" variant="flat" border="gray thin" elevation="0">
            <v-row v-for="(field, index) in fields" :key="index" class="px-4">
              <v-col class="text-left" cols="6">
                <strong>{{ field.label }}:</strong>
              </v-col>
              <v-col class="text-right" cols="6">
                {{ field.key === 'action_Date' || field.key === 'date_Created' ? formatDate(targetTask[field.key]) : (targetTask[field.key] === null || targetTask[field.key] === "" ? 'Not Specified' : targetTask[field.key]) }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <template v-slot:footer>
      <v-btn text="Close" variant="text" @click="closeDialog"></v-btn>
    </template>
  </DialogBase>
</template>

<script>
import DialogBase from './DialogBase.vue';

export default {
  name: 'TaskDetailsDialog',
  components: {
    DialogBase
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    targetTask: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      fields: [
        { label: 'Description', key: 'description' },
        { label: 'Assigned To', key: 'contact_Name' },
        { label: 'Due Date', key: 'action_Date' },
        { label: 'Created By', key: 'created_By' },
        { label: 'Date Created', key: 'date_Created' }
      ]
    };
  },
  methods: {
    closeDialog() {
      this.dialogOpen = false;
    },
    formatDate(dateString) {
      if (!dateString) return 'Not Specified';
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('en-US', options);
    }
  },
  computed: {
    dialogOpen: {
      get() {
        return this.isDialogOpen;
      },
      set(value) {
        this.$emit('update:is-dialog-open', value);
      }
    }
  }
};
</script>

<style scoped>
.text-right {
  overflow-wrap: break-word;
}
</style>
