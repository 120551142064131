// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import { VCalendar } from 'vuetify/labs/VCalendar'
import { VTreeview } from 'vuetify/labs/VTreeview'

export default createVuetify({
  components: {
    VCalendar,
    VTreeview
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#2196F3',
          secondary: '#7C7878',
          accent: '#FFC107',
          error: '#D84315',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
          lightText: '#8c8c8c',
          text: '#212121',
          lightBackground: '#f5f5f9',
          primaryBackground: '#FFFFFF',
        },
      },
      dark: {
        dark: true,
        colors: {
          primary: '#2196F3',
          secondary: '#7C7878', //#9C27b0 previously purple
          accent: '#FFC107',
          error: '#D84315',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
          lightText: '#8c8c8c',
          text: '#FFFFFF',
          lightBackground: '#121212',
          primaryBackground: '#333333',
        },
      },
    },
  }
})