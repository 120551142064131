<template>
  <div>
    <view-container :viewHeader="'Task Hierarchy'">
      <v-container class="pa-0">
        <v-row>
          <v-col cols="12" sm="3">
            <v-card border="gray thin" elevation="0" class="rounded-sm pa-2" min-width="200">

              <h5>Visual Options</h5>


              <!-- Show all parents and children-->
              <v-switch
                class="pl-2"
                v-model="showEntireTreeSwitch"
                color="primary"
                label="Show Entire Tree"
                value="primary"
                hide-details
                @change="applyFilters"
              ></v-switch>
              <h5 class="pb-2">Filter Options</h5>

              <v-select
                variant="outlined"
                density="compact"
                label="Task Name"
                :items="taskNameOptions"
                v-model="filters.taskName"
              >
                <template v-slot:selection="{ item }">
                  <v-chip
                    variant="tonal"
                    color="primary"
                    label
                    closable
                    @click:close="removeTag('Task Name', item)"
                  >
                    {{ item }}
                  </v-chip>
                </template>
              </v-select>

              <v-select
                variant="outlined"
                density="compact"
                label="Department"
                :items="departmentOptions"
                v-model="filters.department"
              >
                <template v-slot:selection="{ item }">
                  <v-chip
                    variant="tonal"
                    color="primary"
                    label
                    closable
                    @click:close="removeTag('Department', item)"
                  >
                    {{ item }}
                  </v-chip>
                </template>
              </v-select>

              <v-select
                variant="outlined"
                density="compact"
                label="Employee"
                :items="employeeOptions"
                v-model="filters.employee"
              >
                <template v-slot:selection="{ item }">
                  <v-chip
                    variant="tonal"
                    color="primary"
                    label
                    closable
                    @click:close="removeTag('Employee', item)"
                  >
                    {{ item }}
                  </v-chip>
                </template>
              </v-select>

              <v-card-actions>
                <v-switch
                  v-model="autoUpdate"
                  :disabled="isUpdating"
                  class="mt-0 ms-2"
                  color="green-lighten-2"
                  density="compact"
                  label="Auto Update"
                  hide-details
                ></v-switch>

                <v-spacer></v-spacer>

                <v-btn
                  :disabled="autoUpdate"
                  :loading="isUpdating"
                  :variant="isUpdating ? 'tonal' : undefined"
                  color="blue-grey-lighten-3"
                  prepend-icon="mdi-update"
                  @click="updateNow"
                >
                  Update Now
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" sm="9" class="pl-0">
            <v-card border="gray thin" elevation="0" class="rounded-sm" min-width="200">
              <div class="pa-2 d-flex align-center justify-space-between">
                <v-btn-group
                  variant="outlined"
                  density="compact"
                  divided
                >            
                  <v-btn size="small" @click="changeViewMode('Quarter Day')">Quarter Day</v-btn>
                  <v-btn size="small" @click="changeViewMode('Half Day')">Half Day</v-btn>
                  <v-btn size="small" @click="changeViewMode('Day')">Day</v-btn>
                  <v-btn size="small" @click="changeViewMode('Week')">Week</v-btn>
                  <v-btn size="small" @click="changeViewMode('Month')">Month</v-btn>
                </v-btn-group>

                <div class="d-flex">
                  <v-btn class="mx-1" border="gray thin" variant="flat" prepend-icon="mdi-plus">Add Task</v-btn>
                  <v-btn class="mx-1" border="gray thin" variant="flat" prepend-icon="mdi-content-save">Save Changes</v-btn>
                </div>
              </div>

              <!-- Gantt Chart container with fixed height and scrollable overflow -->
              <div class="gantt-style pa-2" id="gantt_chart"></div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </view-container>
  </div>
</template>

<script>
import ViewContainer from '../layouts/ViewContainer';

export default {
  name: 'TaskHierarchyView',
  components: {
    ViewContainer,
  },
  data: () => ({
    snackbar: {
      show: false,
      title: '',
      message: '',
      timeout: 3000,
    },
    tags: [
      'Work',
      'Home Improvement',
      'Vacation',
      'Food',
      'Drawers',
      'Shopping',
      'Art',
      'Tech',
      'Creative Writing',
    ],
    selectedTags: [],
    showEntireTreeSwitch: true,
    gantt: null, // Reference to the Gantt chart instance
    filters: {
      taskName: null,
      department: null,
      employee: null,
    },
    taskNameOptions: ['Find sources', 'Outline paper', 'Write paper', 'Project Milestone', 'Review content'],
    departmentOptions: ['Research', 'Planning', 'Development', 'Marketing'],
    employeeOptions: ['John Doe', 'Jane Smith', 'Alex Johnson'],
    autoUpdate: false,
    isUpdating: false,
  }),
  computed: {
    filteredTasks() {
      return this.taskData.filter(task => {
        const matchesTaskName = !this.filters.taskName || task.name.includes(this.filters.taskName);
        const matchesDepartment = !this.filters.department || task.department === this.filters.department;
        const matchesEmployee = !this.filters.employee || task.employee === this.filters.employee;

        if (this.showEntireTreeSwitch) {
          return true; // Show all tasks if the Show Entire Tree switch is enabled
        }

        return matchesTaskName && matchesDepartment && matchesEmployee;
      });
    },
    taskData() {
      return [
        { id: 'Research', name: 'Find sources', start: '2024-10-01', end: '2024-10-05', progress: 100, custom_class: 'cc-task', department: 'Research', employee: 'John Doe', priority: 'High', budget: 5000 },
        { id: 'Outline', name: 'Outline paper', start: '2024-10-06', end: '2024-10-07', progress: 100, dependencies: 'Research', custom_class: 'cc-task', department: 'Planning', employee: 'Jane Smith', priority: 'Medium', budget: 3000 },
        { id: 'Write', name: 'Write paper', start: '2024-10-10', end: '2024-10-13', progress: 25, dependencies: 'Research,Outline', custom_class: 'cc-task', department: 'Development', employee: 'Alex Johnson', priority: 'Low', budget: 2000 },
        { id: 'Review', name: 'Review content', start: '2024-10-16', end: '2024-10-18', progress: 50, dependencies: 'Write', custom_class: 'cc-task', department: 'Planning', employee: 'Jane Smith', priority: 'High', budget: 1500 },
        { id: 'Proofread', name: 'Proofread document', start: '2024-10-19', end: '2024-10-20', progress: 0, dependencies: 'Review', custom_class: 'cc-task', department: 'Development', employee: 'Alex Johnson', priority: 'Medium', budget: 1000 },
        { id: 'Submit', name: 'Submit final document', start: '2024-10-21', end: '2024-10-21', progress: 0, dependencies: 'Proofread', custom_class: 'cc-task', department: 'Marketing', employee: 'John Doe', priority: 'High', budget: 500 },
      ];
    },
  },
  watch: {
    autoUpdate(newVal) {
      if (newVal) {
        this.applyFilters();
      }
    },
    isUpdating(newVal) {
      if (!newVal) {
        this.applyFilters();
      }
    }
  },
  async mounted() {
    await this.loadFrappeGantt();
    this.drawGanttChart('Day');
  },
  methods: {
    loadFrappeGantt() {
      return new Promise((resolve) => {
        if (typeof window.Gantt !== 'undefined') {
          resolve(); // If already loaded
        } else {
          const script = document.createElement('script');
          script.src = 'https://cdn.jsdelivr.net/npm/frappe-gantt/dist/frappe-gantt.umd.js';
          script.onload = () => {
            console.log('Frappe Gantt JavaScript loaded');
            resolve();
          };
          document.head.appendChild(script);

          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = 'https://cdn.jsdelivr.net/npm/frappe-gantt/dist/frappe-gantt.css';
          link.onload = () => {
            console.log('Frappe Gantt CSS loaded');
          };
          document.head.appendChild(link);
        }
      });
    },
    drawGanttChart(viewMode) {
      const tasks = this.filteredTasks;
      if (typeof window.Gantt !== 'undefined') {
        if (this.gantt) {
          this.gantt.refresh(tasks);
        } else {
          this.gantt = new window.Gantt("#gantt_chart", tasks, {
            view_modes: ['Quarter Day', 'Half Day', 'Day', 'Week', 'Month'],
            view_mode: viewMode,
            custom_popup_html: function(task) {
              // Custom popup to show icons for completed tasks
              const completedIcon = task.progress === 100 ? '✅' : '';
              return `
                <div class="details-container">
                  <h5>${task.name} ${completedIcon}</h5>
                  <p>Start: ${task.start}</p>
                  <p>End: ${task.end}</p>
                  <p>Progress: ${task.progress}%</p>
                  <p>Priority: ${task.priority}</p>
                  <p>Budget: $${task.budget}</p>
                </div>
              `;
            }
          });
        }
      } else {
        console.error('Gantt chart library is not loaded.');
      }
    },
    changeViewMode(viewMode) {
      if (this.gantt) {
        this.gantt.change_view_mode(viewMode);
      } else {
        console.error('Gantt chart instance is not available.');
      }
    },
    applyFilters() {
      this.drawGanttChart('Day');
    },
    removeTag(filterType) {
      if (filterType === 'Task Name') this.filters.taskName = null;
      if (filterType === 'Department') this.filters.department = null;
      if (filterType === 'Employee') this.filters.employee = null;
      this.applyFilters();
    },
    updateNow() {
      this.isUpdating = true;
      setTimeout(() => {
        this.isUpdating = false;
      }, 1000);
    }
  }
};
</script>

<style>
.cc-task g rect {
  fill: rgba(188, 188, 188, 0.3) !important;
  stroke: black !important;
  stroke-width: 0.2;
}

.cc-task .bar-wrapper .bar {
  stroke: rgb(0, 174, 255) !important;
  fill: black;
}

.cc-task .bar-progress {
  fill:  rgba(208, 194, 255, 0.7) !important;
}

.cc-task g rect.border {
  background-color: red !important; /* Border color */
  stroke-width: 2px;
  fill: blue;
}

.gantt-style .gantt-container {
  height:850px;
}

.gantt-chart-full {
  color: blue;
  padding: 300;
}
</style>
