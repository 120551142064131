<template>
  <v-container style="max-width: 600px; overflow-y: auto;">
    <div class="d-flex justify-space-between flex-grow-1">
      <v-btn-toggle v-model="selectedFilter" size="small" color="purple" variant="outlined">
        <v-btn prepend-icon="mdi-calendar-today" size="small">Today</v-btn>
        <v-btn prepend-icon="mdi-calendar-week" size="small">Last Week</v-btn>
        <v-btn prepend-icon="mdi-calendar-month" size="small">Last Month</v-btn>
      </v-btn-toggle>
    </div>

    <v-timeline density="compact" side="end">
      <!-- Upcoming Tasks Section -->
      <v-timeline-item :dot-color="'purple'" size="x-small" class="mb-12">
        <span>Upcoming Tasks</span>
      </v-timeline-item>

      <template v-if="todoTasks.length > 0">
        <v-timeline-item
          v-for="(task, index) in todoTasks"
          :key="'todo-' + index"
          :dot-color="getEventColor(task.type)"
          size="x-small"
          class="mb-4"
        >
          <div class="my-3">
            <v-row>
              <v-chip class="ms-0" :color="getEventColor(task.type)" size="x-small" label>
                {{ task.type }}
              </v-chip>
            </v-row>
            <v-row class="pt-1">
              <div class="text-body-2 text-high-emphasis">{{ task.description }}</div>
              <v-icon size="22">mdi-circle-small</v-icon>
              <div class="text-body-2 text-medium-emphasis ">{{ formatTime(task.date) }}</div>
            </v-row>
          </div>
        </v-timeline-item>
      </template>
      <template v-else>
        <!-- No Results Found Message -->
        <v-timeline-item :dot-color="'grey'" size="x-small" class="mb-4">
          <span class="font-italic text-body-2 text-medium-emphasis">No tasks, you're caught up!</span>
        </v-timeline-item>
      </template>

      <!-- Filtered Events Section -->
      <v-timeline-item :dot-color="'purple'" size="x-small" class="mb-12">
        <span>{{ getHeaderTitle() }}</span>
      </v-timeline-item>

      <template v-if="filteredByDate.length > 0">
        <v-timeline-item
          v-for="(event, index) in filteredByDate"
          :key="'event-' + index"
          :dot-color="getEventColor(event.type)"
          size="x-small"
          class="mb-4"
        >
          <div class="my-3">
            <v-row>
              <v-chip class="ms-0" :color="getEventColor(event.type)" size="x-small" label>
                {{ event.type }}
              </v-chip>
            </v-row>
            <v-row class="pt-1">
              <div class="text-body-2 text-high-emphasis">{{ event.description }}</div>
              <v-icon size="22">mdi-circle-small</v-icon>
              <div class="text-body-2 text-medium-emphasis">{{ formatTime(event.date) }}</div>
            </v-row>
          </div>
        </v-timeline-item>
      </template>
      <template v-else>
        <!-- No Results Found Message -->
        <v-timeline-item :dot-color="'grey'" size="x-small" class="mb-4">
          <span class="font-italic text-body-2 text-medium-emphasis">No events found</span>
        </v-timeline-item>
      </template>
    </v-timeline>
  </v-container>
</template>

<script>
import api from '@/services/api';
import { ref, computed } from 'vue';

export default {
  setup() {
    const events = ref([]);
    const selectedFilter = ref(2); // Default to "Last Month"

    // Fetch events when the component is created
    const fetchTimelineEvents = async () => {
      try {
        const response = await api.get('/dashboard/GetTimelineEventsByEmployeeId');
        events.value = response.data || [];
      } catch (error) {
        console.error('Error fetching timeline events:', error);
      }
    };

    // Call the fetch function when the component is created
    fetchTimelineEvents();

    // Computed property to get TODO tasks
    const todoTasks = computed(() => {
      return events.value.filter(event =>
        ['Past Due', 'In Progress', 'Not Started'].includes(event.type)
      );
    });

    // Computed property to filter events based on the selected date range
    const filteredByDate = computed(() => {
      const now = new Date();
      return events.value.filter(event => {
        const eventDate = new Date(event.date);
        if (selectedFilter.value === 0) {
          return eventDate.toDateString() === now.toDateString();
        } else if (selectedFilter.value === 1) {
          const oneWeekAgo = new Date(now);
          oneWeekAgo.setDate(now.getDate() - 7);
          return eventDate >= oneWeekAgo && eventDate <= now;
        } else if (selectedFilter.value === 2) {
          const thirtyDaysAgo = new Date(now);
          thirtyDaysAgo.setDate(now.getDate() - 30);
          return eventDate >= thirtyDaysAgo && eventDate <= now;
        }
      });
    });

    // Function to get the header title based on the selected filter
    const getHeaderTitle = () => {
      if (selectedFilter.value === 0) return 'Today';
      if (selectedFilter.value === 1) return 'Last Week';
      if (selectedFilter.value === 2) return 'Last Month';
    };

    // Function to format the event time
    const formatTime = date => {
      if (!date) return '';
      const eventDate = new Date(date);
      return eventDate.toLocaleTimeString([], {
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });
    };

    // Function to get the event color based on the event type
    const getEventColor = eventType => {
      if (eventType === 'Todo') return 'purple';
      if (eventType === 'Past Due') return 'red';
      if (eventType === 'Created Contact') return 'teal';
      if (eventType === 'Created Task') return 'blue-darken-1';
      if (eventType === 'Created Employee') return 'blue-grey-darken-1';
      if (eventType === 'Completed') return 'green';
      if (eventType === 'In Progress') return 'orange-darken-2';
      if (eventType === 'Not Started') return 'grey';
      return 'grey'; // Default color
    };

    return {
      events,
      selectedFilter,
      todoTasks,
      filteredByDate,
      getHeaderTitle,
      formatTime,
      getEventColor,
    };
  },
};
</script>

<style scoped>
.v-timeline-item.mb-12 span {
  font-weight: bold;
}

.no-results {
  font-style: italic;
}

v-container {
  overflow-y: auto;
  height: 80vh;
}
</style>
