<template>
  <div>
    <view-container :viewHeader="'Management'">

      <!-- Existing Dialogs -->
      <add-contact-dialog
        v-if="dialogs.isAddContactDialogActive"
        :is-dialog-open="dialogs.isAddContactDialogActive"
        @add-contact="addContact"
        @show-snackbar="emitSnackbarEvent"
        @refresh-contacts="refreshContacts"
        @update:is-dialog-open="dialogs.isAddContactDialogActive = $event">
      </add-contact-dialog>

      <edit-contact-dialog
        v-if="dialogs.isEditContactDialogActive"
        :targetContact="selectedContact"
        :is-dialog-open="dialogs.isEditContactDialogActive"
        @show-snackbar="emitSnackbarEvent"
        @edit-contact="editContact"
        @refresh-contacts="refreshContacts"
        @update:is-dialog-open="dialogs.isEditContactDialogActive = $event">
      </edit-contact-dialog>

      <archive-contact-dialog
        v-if="dialogs.isArchiveContactDialogActive"
        :targetContact="selectedContact"
        :is-dialog-open="dialogs.isArchiveContactDialogActive"
        @show-snackbar="emitSnackbarEvent"
        @archive-contact="archiveContact"
        @refresh-contacts="refreshContacts"
        @update:is-dialog-open="dialogs.isArchiveContactDialogActive = $event">
      </archive-contact-dialog>

      <detailed-contact-dialog
        v-if="dialogs.isDetailedContactDialogActive"
        :targetContact="selectedContact"
        :is-dialog-open="dialogs.isDetailedContactDialogActive"
        @update:is-dialog-open="dialogs.isDetailedContactDialogActive = $event">
      </detailed-contact-dialog>

      <contact-address-dialog
        v-if="dialogs.isContactAddressDialogActive"
        :targetContact="selectedContact"
        :is-dialog-open="dialogs.isContactAddressDialogActive"
        @update:is-dialog-open="dialogs.isContactAddressDialogActive = $event">
      </contact-address-dialog>

      <contact-tasks-dialog
        v-if="dialogs.isContactTasksDialogActive"
        :targetContact="selectedContact"
        :is-dialog-open="dialogs.isContactTasksDialogActive"
        @show-snackbar="emitSnackbarEvent"
        @refresh-contacts="refreshContacts"
        @update:is-dialog-open="dialogs.isContactTasksDialogActive = $event">
      </contact-tasks-dialog>

      <contact-data-options-dialog
        v-if="dialogs.isDataOptionsDialogActive"
        :is-dialog-open="dialogs.isDataOptionsDialogActive"
        @show-snackbar="emitSnackbarEvent"
        @refresh-contacts="refreshContacts"
        @update:is-dialog-open="dialogs.isDataOptionsDialogActive = $event">
      </contact-data-options-dialog>


      <v-card border="gray thin" elevation="0" min-width="200" class="rounded-sm">
        <template v-slot:text v-if="this.$vuetify.display.mobile">
          <v-row dense>
            <v-col>
              <v-text-field
                clearable
                v-model="search"
                label="Search"
                prepend-inner-icon="mdi-magnify"
                variant="outlined"
                hide-details
                single-line
                density="compact"
              ></v-text-field>
            </v-col>

            <v-col cols="auto">
              <v-btn @click="refreshBtnClicked" block size="x-small" height="40" class="px-4" color="secondary" variant="tonal">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="4">
              <v-btn block @click="dialogs.isAddContactDialogActive = true" prepend-icon="mdi-plus" size="x-small" height="40" class="px-4" color="primary" variant="tonal">
                New Contact
              </v-btn>
            </v-col>

            <v-col cols="4">
              <v-btn block prepend-icon="mdi-export" size="x-small" height="40" class="px-4" @click="dialogs.isDataOptionsDialogActive = true" color="secondary" variant="tonal">
                Data
              </v-btn>
            </v-col>

            <v-col cols="4">
              <v-menu
                max-width="430"
                v-model="advancedFilterMenu"
                :close-on-content-click="false"
                location="bottom"
              >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" block prepend-icon="mdi-filter" size="x-small" height="40" :class="activeFilterCount ? 'pl-4 pr-2 px-4' : 'px-4'" color="purple" variant="tonal">
                    Filter
                    <template v-slot:append>
                      <v-badge v-if="activeFilterCount" :content="activeFilterCount" inline color="secondary"></v-badge>
                    </template> 

                  </v-btn>
                </template>

                <v-card min-width="300">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="pt-3">
                        <p><strong>Advanced Filters</strong></p>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field v-model="filters.name" prepend-inner-icon="mdi-account" variant="outlined" density="compact" label="Name"></v-text-field>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field v-model="filters.company" prepend-inner-icon="mdi-domain" variant="outlined" density="compact" label="Company"></v-text-field>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field v-model="filters.title" prepend-inner-icon="mdi-briefcase-outline" variant="outlined" density="compact" label="Title"></v-text-field>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field v-model="filters.accountHolder" prepend-inner-icon="mdi-account-supervisor" variant="outlined" density="compact" label="Account Manager"></v-text-field>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-select
                          :items="contactTypes"
                          v-model="filters.contactType"
                          item-title="type"
                          item-value="type_ID"
                          variant="outlined"
                          density="compact"
                          label="Contact Type"
                          clearable
                        ></v-select>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field v-model="filters.taskCount" prepend-inner-icon="mdi-calendar-check" variant="outlined" density="compact" label="Minimum Task Count" type="number"></v-text-field>
                      </v-col>

                      <v-col cols="12" class="pt-3">
                        <p><strong>Date Added Between</strong></p>
                      </v-col>

                      <v-col cols="6" class="py-0 pr-1">
                        <v-text-field v-model="filters.startDate" prepend-inner-icon="mdi-calendar" variant="outlined" density="compact" label="Start Date" type="date"></v-text-field>
                      </v-col>

                      <v-col cols="6" class="py-0 pl-1">
                        <v-text-field v-model="filters.endDate" prepend-inner-icon="mdi-calendar" variant="outlined" density="compact" label="End Date" type="date"></v-text-field>
                      </v-col>

                    </v-row>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn variant="text" @click="resetFilters">Reset</v-btn>
                    <v-btn color="primary" variant="tonal" @click="applyFilters">Apply</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>

          </v-row>
        </template>

        <template v-slot:text v-else>
          <v-row dense>
            <v-col cols="auto">
              <v-btn block @click="dialogs.isAddContactDialogActive = true" prepend-icon="mdi-plus" size="x-small" height="40" class="px-4" color="primary" variant="tonal">
                New Contact
              </v-btn>
            </v-col>

            <v-col cols="auto">
              <v-btn block prepend-icon="mdi-export" size="x-small" height="40" class="px-4" @click="dialogs.isDataOptionsDialogActive = true" color="secondary" variant="tonal">
                Data Options
              </v-btn>
            </v-col>

            <v-col cols="auto">
              <v-btn @click="refreshBtnClicked" block prepend-icon="mdi-refresh" size="x-small" height="40" class="px-4" color="secondary" variant="tonal">
                Refresh
              </v-btn>
            </v-col>

            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-menu
                max-width="430"
                v-model="advancedFilterMenu"
                :close-on-content-click="false"
                location="bottom"
              >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" block prepend-icon="mdi-filter" size="x-small" height="40" :class="activeFilterCount ? 'pl-4 pr-2 px-4' : 'px-4'" color="secondary" variant="tonal">
                    Advanced Filters

                    <template v-slot:append>
                      <v-badge v-if="activeFilterCount" :content="activeFilterCount" inline color="secondary"></v-badge>
                    </template> 

                  </v-btn>
                </template>

                <v-card min-width="300">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="pt-3">
                        <p><strong>Advanced Filters</strong></p>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field v-model="filters.name" prepend-inner-icon="mdi-account" variant="outlined" density="compact" label="Name"></v-text-field>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field v-model="filters.company" prepend-inner-icon="mdi-domain" variant="outlined" density="compact" label="Company"></v-text-field>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field v-model="filters.title" prepend-inner-icon="mdi-briefcase-outline" variant="outlined" density="compact" label="Title"></v-text-field>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field v-model="filters.accountHolder" prepend-inner-icon="mdi-account-supervisor" variant="outlined" density="compact" label="Asignee"></v-text-field>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-select
                          :items="contactTypes"
                          v-model="filters.contactType"
                          item-title="type"
                          item-value="type_ID"
                          variant="outlined"
                          density="compact"
                          label="Contact Type"
                          clearable
                        ></v-select>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field v-model="filters.taskCount" prepend-inner-icon="mdi-calendar-check" variant="outlined" density="compact" label="Minimum Task Count" type="number"></v-text-field>
                      </v-col>

                      <v-col cols="12" class="pt-3">
                        <p><strong>Date Added Between</strong></p>
                      </v-col>

                      <v-col cols="6" class="py-0 pr-1">
                        <v-text-field v-model="filters.startDate" prepend-inner-icon="mdi-calendar" variant="outlined" density="compact" label="Start Date" type="date"></v-text-field>
                      </v-col>

                      <v-col cols="6" class="py-0 pl-1">
                        <v-text-field v-model="filters.endDate" prepend-inner-icon="mdi-calendar" variant="outlined" density="compact" label="End Date" type="date"></v-text-field>
                      </v-col>

                    </v-row>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn variant="text" @click="resetFilters">Reset</v-btn>
                    <v-btn color="primary" variant="tonal" @click="applyFilters">Apply</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>

            <v-col cols="4">
              <v-text-field
                clearable
                v-model="search"
                label="Search"
                prepend-inner-icon="mdi-magnify"
                variant="outlined"
                hide-details
                single-line
                density="compact"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>

        <v-data-table 
          :sort-by="this.sortBy"
          :mobile="this.$vuetify.display.mobile" 
          mobile-breakpoint="sm"
          :search="search" 
          :headers="headers"
          :items="filteredContacts"
        >

          <template v-slot:[`item.type`]="{ item }">
            <v-chip label color="secondary">            
              <v-icon class="pr-2">{{ item.icon}}</v-icon>
              {{ item.type || 'unknown'}}
            </v-chip>
          </template>

          <template v-slot:[`item.task_Count`]="{ item }">
            <v-btn :color="getTaskColor(item.task_Count)" @click="toggleContactTasksDialog(item)" prepend-icon="mdi-calendar-check" class="my-2 text-none" size="small" variant="tonal">{{ item.task_Count }} Tasks
            </v-btn>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn class="mx-1" variant="plain" density="compact" icon="mdi-account-details-outline" @click="toggleDetailedContactDialog(item)"></v-btn>
            <v-btn class="mx-1" variant="plain" density="compact" icon="mdi-map-marker-outline" @click="toggleContactAddressDialog(item)"></v-btn>
            <v-btn class="mx-1" variant="plain" density="compact" icon="mdi-pencil-outline" @click="toggleEditContactDialog(item)"></v-btn>
            <v-btn class="mx-1" variant="plain" density="compact" icon="mdi-archive-outline" @click="toggleArchiveContactDialog(item)"></v-btn>
          </template>

          <template v-slot:[`item.company_Name`]="{ item }">
            {{ item.company_Name || 'unknown'}}
          </template>

          <template v-slot:[`item.account_Holder`]="{ item }">
            {{ item.account_Holder || 'unknown'}}
          </template>

          <template v-slot:[`item.title`]="{ item }">
            {{ item.title || 'unknown'}}
          </template>

          <template v-slot:[`item.date_Created`]="{ item }">
            {{ formatDate(item.date_Created) }}
          </template>

          <template v-slot:no-data>
            <div>No contacts were found</div>
          </template>
        </v-data-table>
      </v-card>
    </view-container>
  </div>
</template>

<script>
import api from '@/services/api'
import eventBus from '@/services/eventBus';
import ViewContainer from '../layouts/ViewContainer'
import AddContactDialog from '../../components/dialogs/AddContactDialog'
import EditContactDialog from '../../components/dialogs/EditContactDialog'
import ArchiveContactDialog from '../../components/dialogs/ArchiveContactDialog'
import DetailedContactDialog from '../../components/dialogs/DetailedContactDialog'
import ContactAddressDialog from '../../components/dialogs/ContactAddressDialog'
import ContactTasksDialog from '../../components/dialogs/ContactTasksDialog'
import ContactDataOptionsDialog from '../../components/dialogs/ContactDataOptionsDialog.vue'

export default {
  name: 'ContactsView',
  components: {
    ViewContainer,
    AddContactDialog,
    EditContactDialog,
    ArchiveContactDialog,
    DetailedContactDialog,
    ContactAddressDialog,
    ContactTasksDialog,
    ContactDataOptionsDialog
  },
  data: () => ({
    contacts: [],
    selectedContact: null,
    search: '',
    advancedFilterMenu: false,
    sortBy: [{ key: 'task_Count', order: 'desc' }],

    dialogs: {
      isAddContactDialogActive: false,
      isEditContactDialogActive: false,
      isArchiveContactDialogActive: false,
      isDetailedContactDialogActive: false,
      isContactAddressDialogActive: false,
      isContactTasksDialogActive: false,
    },

    isContactsDatagridLoading: true,

    snackbar: {
      show: false,
      title: '',
      message: '',
      timeout: 3000
    },

    headers: [
      { title: 'Id', key: 'contact_ID' },
      { title: 'Contact Type', key: 'type' },
      { title: 'Full Name', key: 'full_Name' },
      { title: 'Title', key: 'title' },
      { title: 'Company', key: 'company_Name' },
      { title: 'Task Count', key: 'task_Count', sortable: true },
      { title: 'Assignee', key: 'account_Holder' },
      { title: 'Date Added', key: 'date_Created', sortable: true },
      { title: 'Actions', key: 'actions' }
    ],

    filters: {
      name: '',
      company: '',
      title: '',
      accountHolder: '',
      startDate: '',
      endDate: '',
      taskCount: '',
      contactType: ''
    },

    contactTypes: []
  }),
  computed: {
    filteredContacts() {
      return this.contacts.filter(contact => {
        const matchesName = this.filters.name ? contact.full_Name?.toLowerCase().includes(this.filters.name.toLowerCase()) : true;
        const matchesCompany = this.filters.company ? contact.company_Name?.toLowerCase().includes(this.filters.company.toLowerCase()) : true;
        const matchesTitle = this.filters.title ? contact.title?.toLowerCase().includes(this.filters.title.toLowerCase()) : true;
        const matchesAccountHolder = this.filters.accountHolder ? contact.account_Holder?.toLowerCase().includes(this.filters.accountHolder.toLowerCase()) : true;
        const matchesStartDate = this.filters.startDate ? new Date(contact.date_Created) >= new Date(this.filters.startDate) : true;
        const matchesEndDate = this.filters.endDate ? new Date(contact.date_Created) <= new Date(this.filters.endDate) : true;
        const matchesTaskCount = this.filters.taskCount ? contact.task_Count == this.filters.taskCount : true;
        const matchesContactType = this.filters.contactType ? contact.type_ID === this.filters.contactType : true;
        return matchesName && matchesCompany && matchesTitle && matchesAccountHolder && matchesStartDate && matchesEndDate && matchesTaskCount && matchesContactType;
      });
    },
    activeFilterCount() {
      return Object.values(this.filters).filter(value => value).length;
    }
  },
  async mounted() {
    await this.getContactsWithTimeout();
    await this.getContactTypes();
  },
  methods: {
    emitSnackbarEvent(payload) {
      eventBus.emit('show-snackbar', payload);
    },
    getTaskColor(taskCount) {
      if (taskCount < 1) {
        return 'primary';
      } else if (taskCount < 3) {
        return 'orange-darken-3';
      } else {
        return 'red-darken-1';
      }
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString('en-US', options);
    },
    async getContactsWithTimeout(showNotification = false) {
      this.isContactsDatagridLoading = true;
      try {
        const success = await this.getContactsAsync();
        if (success && showNotification) {
          this.showSnackbar('success', 'mdi-check-outline', 'Contacts Were Refreshed!');
        }
      } finally {
        this.isContactsDatagridLoading = false;
      }
    },
    async getContactsAsync() {
      try {
        const response = await api.get('/Contact/GetAllContacts/');
        if (response.status === 200) {
          this.contacts = response.data;
          console.log(this.contacts);
          return true;
        } else {
          this.showSnackbar('error', 'mdi-wifi-off', 'Unable to Retrieve Contacts', 'An error occurred while retrieving contacts. Try refreshing the page.');
          return false;
        }
      } catch (error) {
        if (error.request) {
          console.log('connection dropped');
          //this.showSnackbar('error', 'mdi-wifi-off', 'Unable to Retrieve Contacts', 'The network connection to the API has been lost.');
        } else {
          this.showSnackbar('error', 'mdi-wifi-off', 'Unable to Retrieve Contacts', 'An internal error occurred while attempting to retrieve contacts.');
        }
        return false;
      }
    },
    async getContactTypes() {
      try {
        const response = await api.get('/Contact/GetContactTypes/');
        if (response.status === 200) {
          this.contactTypes = response.data;
          console.log(this.contactTypes);
        } else {
          console.log('Internal error');
        }
      } catch (error) {
        console.error(error);
      }
    },
    refreshContacts(showNotification = false) {
      return this.getContactsWithTimeout(showNotification);
    },
    refreshBtnClicked() {
      this.refreshContacts(true);
    },
    showSnackbar(color, icon, title, description) {
      this.emitSnackbarEvent({ color, icon, title, description });
    },
    toggleAddContactDialog() {
      this.dialogs.isAddContactDialogActive = !this.dialogs.isAddContactDialogActive;
    },
    toggleEditContactDialog(contact) {
      this.selectedContact = contact;
      this.dialogs.isEditContactDialogActive = !this.dialogs.isEditContactDialogActive;
    },
    toggleArchiveContactDialog(contact) {
      this.selectedContact = contact;
      this.dialogs.isArchiveContactDialogActive = !this.dialogs.isArchiveContactDialogActive;
    },
    toggleDetailedContactDialog(contact) {
      this.selectedContact = contact;
      this.dialogs.isDetailedContactDialogActive = !this.dialogs.isDetailedContactDialogActive;
    },
    toggleContactAddressDialog(contact) {
      this.selectedContact = contact;
      this.dialogs.isContactAddressDialogActive = !this.dialogs.isContactAddressDialogActive;
    },
    toggleContactTasksDialog(contact) {
      this.selectedContact = contact;
      this.dialogs.isContactTasksDialogActive = !this.dialogs.isContactTasksDialogActive;
    },
    applyFilters() {
      this.advancedFilterMenu = false;
    },
    resetFilters() {
      this.filters.name = '';
      this.filters.company = '';
      this.filters.title = '';
      this.filters.accountHolder = '';
      this.filters.startDate = '';
      this.filters.endDate = '';
      this.filters.taskCount = '';
      this.filters.contactType = '';
      this.advancedFilterMenu = false;
    }
  }
};
</script>

<style scoped>
.full-background {
  min-height: 100vh;
}
</style>
