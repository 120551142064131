<template>
  <div>
    
    <task-confirmation-dialog
      v-if="dialogs.isUpdateTaskStatusDialogActive"
      :targetTask="selectedTask"
      :is-dialog-open="dialogs.isUpdateTaskStatusDialogActive"
      @confirmed-clicked="completeTask"
      @show-snackbar="emitSnackbarEvent"
      @refresh-tasks="refreshTasks"
      @update:is-dialog-open="dialogs.isUpdateTaskStatusDialogActive = $event">
    </task-confirmation-dialog>

    <view-container :viewHeader="'Management'">
      <v-card border="gray thin" elevation="0" class="mx-auto">
        <template v-slot:text v-if="this.$vuetify.display.mobile">
          <v-row dense>
            <v-col>
              <v-text-field
                clearable
                v-model="search"
                label="Search"
                prepend-inner-icon="mdi-magnify"
                variant="outlined"
                hide-details
                single-line
                density="compact"
              ></v-text-field>
            </v-col>

            <v-col cols="auto">
              <v-btn @click="refreshTasksBtnClicked" block size="x-small" height="40" class="px-4" color="secondary" variant="tonal">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="4">
              <v-btn block @click="toggleAddTaskDialog" prepend-icon="mdi-plus" size="x-small" height="40" class="px-4" color="primary" variant="tonal">
                New Task
              </v-btn>
            </v-col>

            <v-col cols="8">
              <v-menu
                v-model="advancedFilterMenu"
                :close-on-content-click="false"
                location="bottom"
                max-width="430"
              >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" block prepend-icon="mdi-filter" size="x-small" height="40" :class="activeFilterCount ? 'pl-4 pr-2 px-4' : 'px-4'" color="secondary" variant="tonal">
                    Filters

                    <template v-slot:append>
                      <v-badge v-if="activeFilterCount" :content="activeFilterCount" inline color="secondary"></v-badge>
                    </template>

                  </v-btn>
                </template>

                <v-card min-width="300">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="pt-3">
                        <p><strong>Advanced Filters</strong></p>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field v-model="filters.createdBy" prepend-inner-icon="mdi-account-edit" variant="outlined" density="compact" label="Created By"></v-text-field>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field v-model="filters.originatingContact" prepend-inner-icon="mdi-account" variant="outlined" density="compact" label="Originating Contact"></v-text-field>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-select
                          :items="taskTypes"
                          v-model="filters.taskType"
                          item-title="type"
                          item-value="type_ID"
                          variant="outlined"
                          density="compact"
                          label="Task Type"
                          clearable
                        ></v-select>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field v-model="filters.description" prepend-inner-icon="mdi-text" variant="outlined" density="compact" label="Description"></v-text-field>
                      </v-col>

                      <v-col cols="12" class="pt-0">
                        <p><strong>Created Date Between</strong></p>
                      </v-col>

                      <v-col cols="6" class="py-0 pr-1">
                        <v-text-field v-model="filters.startDate" prepend-inner-icon="mdi-calendar" variant="outlined" density="compact" label="Start Date" type="date"></v-text-field>
                      </v-col>

                      <v-col cols="6" class="py-0 pl-1">
                        <v-text-field v-model="filters.endDate" prepend-inner-icon="mdi-calendar" variant="outlined" density="compact" label="End Date" type="date"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn variant="text" @click="resetFilters">Reset</v-btn>
                    <v-btn color="primary" variant="tonal" @click="applyFilters">Apply</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </template>

        <template v-slot:text v-else>
          <v-row dense>

            <v-col cols="auto">
              <v-menu
                :close-on-content-click="false"
                location="bottom"
                max-width="430"
              >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" block prepend-icon="mdi-eye-outline" size="x-small" height="40" class="px-4" color="secondary" variant="tonal">
                    View Options
                  </v-btn>
                </template>

                <v-card min-width="300">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="pt-3">
                        <p><strong>Advanced Filters</strong></p>
                      </v-col>

                      <v-col cols="12" class="pt-3">
                        <v-switch inset label="Gantt View"> </v-switch>
                      </v-col>
                      
                    </v-row>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn variant="text" @click="resetDisplayOptions">Reset</v-btn>
                    <v-btn color="primary" variant="tonal" @click="applyDisplayOptions">Apply</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>

            <v-col cols="auto">
              <v-btn 
                @click="refreshTasksBtnClicked"
                block 
                prepend-icon="mdi-refresh"
                size="x-small"
                height="40"
                class="px-4"
                color="secondary" 
                variant="tonal">
                Refresh
              </v-btn>
            </v-col>

            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-menu
                v-model="advancedFilterMenu"
                :close-on-content-click="false"
                location="bottom"
                max-width="430"
              >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" block prepend-icon="mdi-filter" size="x-small" height="40" :class="activeFilterCount ? 'pl-4 pr-2 px-4' : 'px-4'" color="secondary" variant="tonal">
                    Advanced Filters

                    <template v-slot:append>
                      <v-badge v-if="activeFilterCount" :content="activeFilterCount" inline color="secondary"></v-badge>
                    </template>

                  </v-btn>
                </template>

                <v-card min-width="300">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="pt-3">
                        <p><strong>Advanced Filters</strong></p>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field v-model="filters.createdBy" prepend-inner-icon="mdi-account-edit" variant="outlined" density="compact" label="Created By"></v-text-field>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field v-model="filters.originatingContact" prepend-inner-icon="mdi-account" variant="outlined" density="compact" label="Originating Contact"></v-text-field>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-select
                          :items="taskTypes"
                          v-model="filters.taskType"
                          item-title="type"
                          item-value="type_ID"
                          variant="outlined"
                          density="compact"
                          label="Task Type"
                          clearable
                        ></v-select>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field v-model="filters.description" prepend-inner-icon="mdi-text" variant="outlined" density="compact" label="Description"></v-text-field>
                      </v-col>

                      <v-col cols="12" class="pt-0">
                        <p><strong>Created Date Between</strong></p>
                      </v-col>

                      <v-col cols="6" class="py-0 pr-1">
                        <v-text-field v-model="filters.startDate" prepend-inner-icon="mdi-calendar" variant="outlined" density="compact" label="Start Date" type="date"></v-text-field>
                      </v-col>

                      <v-col cols="6" class="py-0 pl-1">
                        <v-text-field v-model="filters.endDate" prepend-inner-icon="mdi-calendar" variant="outlined" density="compact" label="End Date" type="date"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn variant="text" @click="resetFilters">Reset</v-btn>
                    <v-btn color="primary" variant="tonal" @click="applyFilters">Apply</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>

            <v-col cols="4">
              <v-text-field
                clearable
                v-model="search"
                label="Search"
                prepend-inner-icon="mdi-magnify"
                variant="outlined"
                hide-details
                single-line
                density="compact"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>

        <v-data-table
          :sort-by="sortBy"
          :mobile="this.$vuetify.display.mobile" mobile-breakpoint="sm"
          :search="search"
          :headers="headers"
          :items="filteredTasks"
        >

          <template v-slot:[`item.type`]="{ item }">
            <v-chip label color="grey">            
              <v-icon class="pr-2">{{ item.type_Icon}}</v-icon>
              {{ item.type || 'unknown'}}
            </v-chip>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-tooltip text="Update Status" v-if="item.status === 'In Progress'">
              <template v-slot:activator="{ props }">
                <v-chip v-bind="props" @click="toggleUpdateTaskStatusDialog(item)" :prepend-icon="item.status_Icon" v-if="item.status === 'In Progress'" label color="orange-darken-2">
                  In Progress
                </v-chip>
              </template>
            </v-tooltip>

            <v-tooltip text="Update Status" v-else-if="item.status === 'Completed'">
              <template v-slot:activator="{ props }">
                <v-chip v-bind="props" @click="toggleUpdateTaskStatusDialog(item)" :prepend-icon="item.status_Icon" label color="success">
                  Completed
                </v-chip>
              </template>
            </v-tooltip>

            <v-tooltip text="Update Status" v-else-if="item.status === 'Archived'">
              <template v-slot:activator="{ props }">
                <v-chip v-bind="props" @click="toggleUpdateTaskStatusDialog(item)" :prepend-icon="item.status_Icon" label color="blue-grey">
                  Archived
                </v-chip>
              </template>
            </v-tooltip>

            <v-tooltip location="bottom" text="Update Status" v-else>
              <template v-slot:activator="{ props }">
                <v-chip v-bind="props" @click="toggleCompleteTaskDialog(item)" prepend-icon="mdi-help-circle-outline" label color="grey">
                  Unknown Status
                </v-chip>
              </template>
            </v-tooltip>
          </template>

          <template v-slot:[`item.title`]="{ item }">
            {{ item.title || 'unknown'}}
          </template>

          <template v-slot:[`item.description`]="{ item }">
            {{ item.description || 'unknown'}}
          </template>

          <template v-slot:[`item.action_Date`]="{ item }">
            <span v-if="!item.action_Date" class="text-gray">Not Specified</span>
            <span v-else-if="isDatePassed(item.action_Date) && !item.is_Completed" class="text-red">
              <v-icon small left>mdi-alert-circle-outline</v-icon> {{ formatDate(item.action_Date) }}
            </span>
            <span v-else-if="item.is_Completed" class="text-gray">{{ formatDate(item.action_Date) }}</span>
            <span v-else>{{ formatDate(item.action_Date) }}</span>
          </template>

          <template v-slot:[`item.date_Created`]="{ item }">
            {{ formatDate(item.date_Created) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip text="Mark as Complete" v-if="!item.is_Completed">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" class="mx-1" variant="plain" density="compact" icon="mdi-check-bold"
                  @click="toggleCompleteTaskDialog(item)">
                </v-btn>
              </template>
            </v-tooltip>
            <v-tooltip text="Undo Completion" v-else>
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" class="mx-1" variant="plain" density="compact" icon="mdi-undo"
                  @click="toggleUndoCompleteTaskDialog(item)">
                </v-btn>
              </template>
            </v-tooltip>
            <v-tooltip text="Edit Task">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" class="mx-1" variant="plain" density="compact" icon="mdi-pencil-outline"
                  @click="toggleEditTaskDialog(item)">
                </v-btn>
              </template>
            </v-tooltip>
            <v-tooltip text="View Task Information">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" class="mx-1" variant="plain" density="compact" icon="mdi-information-outline"
                  @click="toggleTaskInformationDialog(item)">
                </v-btn>
              </template>
            </v-tooltip>
          </template>


          <template v-slot:no-data>
            <div>All tasks are completed!</div>
          </template>
        </v-data-table>
      </v-card>
    </view-container>
  </div>
</template>

<script>
import api from '@/services/api'
import eventBus from '@/services/eventBus';
import ViewContainer from '../layouts/ViewContainer'
import TaskConfirmationDialog from '@/components/dialogs/TaskConfirmationDialog.vue';


export default {
  name: 'UserTasksView',
  components: {
    ViewContainer,
    TaskConfirmationDialog,

  },
  data: () => ({
    search: '',
    advancedFilterMenu: false,
    displayFilterMenu: false,
    isUserTaskDatagridLoading: true,
    UserTasks: [],
    selectedTask: null,
    sortBy: [{ key: 'date_Created', order: 'desc' }],

    dialogs: {
      isAddTaskDialogActive: false,
      isUpdateTaskStatusDialogActive: false,
      isEditTaskDialogActive: false,
      isDetailedTaskDialogActive: false,
      isAddEmployeesToTaskDialogActive: false,
    },

    headers: [
      { "title": "Id", "key": "user_Task_ID" },
      { "title": "Task Type", "key": "type" },
      { "title": "Description", "key": "description" },
      { "title": "Status", "key": "status" },
      { "title": "Due Date", "key": "action_Date" },
      { "title": "Opened Date", "key": "date_Created" },
    ],

    filters: {
      createdBy: '',
      originatingContact: '',
      taskType: '',
      description: '',
      startDate: '',
      endDate: ''
    },

    snackbar: {
      show: false,
      title: '',
      message: '',
      timeout: 3000
    },

    taskTypes: [], // Added taskTypes to data
  }),
  computed: {
    filteredTasks() {
      return this.UserTasks.filter(task => {
        const matchesCreatedBy = this.filters.createdBy ? task.created_By?.toLowerCase().includes(this.filters.createdBy.toLowerCase()) : true;
        const matchesOriginatingContact = this.filters.originatingContact ? task.contact_Name?.toLowerCase().includes(this.filters.originatingContact.toLowerCase()) : true;
        const matchesTaskType = this.filters.taskType ? task.type_ID === this.filters.taskType : true;
        const matchesDescription = this.filters.description ? task.description?.toLowerCase().includes(this.filters.description.toLowerCase()) : true;
        const matchesStartDate = this.filters.startDate ? new Date(task.date_Created) >= new Date(this.filters.startDate) : true;
        const matchesEndDate = this.filters.endDate ? new Date(task.date_Created) <= new Date(this.filters.endDate) : true;
        return matchesCreatedBy && matchesOriginatingContact && matchesTaskType && matchesDescription && matchesStartDate && matchesEndDate;
      });
    },
    activeFilterCount() {
      return Object.values(this.filters).filter(value => value !== '' && value !== null).length;
    }
  },
  async mounted() {
    await this.getTaskTypes();
    await this.getUserTasksAsync();
  },
  methods: {
    isDatePassed(date) {
      return new Date(date) < new Date();
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString('en-US', options);
    },
    async getUserTasksAsync(showNotification = false) {
      this.isUserTaskDatagridLoading = true;
      try {
        const response = await api.get('/UserTask/GetAllUserTasksByEmployeeId');
        await new Promise(resolve => setTimeout(resolve, 100));

        if (response.status === 200) {
          this.UserTasks = response.data;
          console.log(this.UserTasks);
          if (showNotification) {
            this.showSnackbar('success', 'mdi-check-circle', 'Tasks Were Refreshed!', 'Contact tasks are now up to date.');
          }
        } else if (response.status === 429) {
          this.tooManyRequestsDialog = true;
        } else {
          this.showSnackbar('error', 'mdi-alert-circle', 'Unable to Retrieve Tasks', 'An unexpected error occurred.');
        }
      } catch (error) {
        if (error.request) {
          this.showSnackbar('error', 'mdi-wifi-off', 'No Response from Server', 'Please check your connection.');
        } else {
          this.showSnackbar('error', 'mdi-alert-octagram', 'Unable to Retrieve Tasks', 'An error occurred while processing your request.');
        }
      } finally {
        this.isUserTaskDatagridLoading = false;
      }
    },
    async getTaskTypes() {
      try {
        const response = await api.get('/TaskType/GetTaskTypes');
        if (response.status === 200) {
          this.taskTypes = response.data;
        } else {
          console.log('Internal error');
        }
      } catch (error) {
        console.error(error);
      }
    },
    refreshTasks(showNotification = false) {
      this.getUserTasksAsync(showNotification);
    },
    refreshTasksBtnClicked() {
      this.refreshTasks(true);
    },
    showSnackbar(color, icon, title, description) {
      this.emitSnackbarEvent({ color, icon, title, description });
    },
    emitSnackbarEvent(payload) {
      eventBus.emit('show-snackbar', payload);
    },
    toggleUpdateTaskStatusDialog(task) {
      this.selectedTask = task;
      this.dialogs.isUpdateTaskStatusDialogActive = !this.dialogs.isUpdateTaskStatusDialogActive;
    },
    toggleEditTaskDialog(task) {
      this.selectedTask = task;
      this.dialogs.isEditTaskDialogActive = !this.dialogs.isEditTaskDialogActive;
    },
    toggleTaskInformationDialog(task) {
      this.selectedTask = task;
      this.dialogs.isDetailedTaskDialogActive = !this.dialogs.isDetailedTaskDialogActive;
    },
    toggleAddTaskDialog() {
      this.dialogs.isAddTaskDialogActive = !this.dialogs.isAddTaskDialogActive;
    },
    toggleAddEmployeesToTaskDialog(task) {
      this.selectedTask = task;
      this.dialogs.isAddEmployeesToTaskDialogActive = !this.dialogs.isAddEmployeesToTaskDialogActive;
    },
    undoCompleteTask() {
      console.log("hit");
    },
    completeTask() {
      console.log("hit");
    },
    applyFilters() {
      this.advancedFilterMenu = false;
    },
    resetFilters() {
      this.filters = {
        createdBy: '',
        originatingContact: '',
        taskType: '',
        description: '',
        startDate: '',
        endDate: ''
      };
      this.advancedFilterMenu = false;
    },
    applyDisplayFilters() {
      this.displayFilterMenu = false;
    },
    resetDisplayFilters() {
      this.filters = {
        createdBy: '',
        originatingContact: '',
        taskType: '',
        description: '',
        startDate: '',
        endDate: ''
      };
      this.displayFilterMenu = false;
    }
  }
};
</script>

<style scoped>
.full-background {
  min-height: 100vh;
}
.text-red {
  color: red;
}
.text-gray {
  color: rgb(180, 180, 180);
}
</style>
