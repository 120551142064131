<template>
  <DialogBase :isDialogVisible="dialogOpen">
    <template v-slot:header>
      <span>Create New Task</span>
    </template>

    <template v-slot:actions>
      <v-tooltip location="left" text="Exit without saving">
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            class="ma-1 mr-2"
            icon="mdi-close"
            variant="tonal"
            color="gray"
            size="small"
            @click="closeDialog"
          >
          </v-btn>
        </template>
      </v-tooltip>
    </template>

    <template v-slot:content>
      <v-form ref="form" v-model="valid" lazy-validation>
        <!-- Stepper Control -->
        <v-stepper non-linear flat class="pa-1" v-model="step">
          <v-stepper-header height="10">
            <v-stepper-item title="Task Info" value="0"></v-stepper-item>
            <v-divider></v-divider>
            <v-stepper-item title="Parent Task" value="1"></v-stepper-item>
            <v-divider></v-divider>
            <v-stepper-item title="Assign" value="2"></v-stepper-item>
          </v-stepper-header>

          <!-- Stepper Window Content -->
          <v-stepper-window class="pa-0 ma-0">
            <!-- Task Details Step -->
            <v-stepper-window-item :value="0">
              <v-card class="ma-0 pa-2">
                <v-select
                  :items="taskTypes"
                  v-model="newTask.type_ID"
                  item-title="type"
                  item-value="type_ID"
                  variant="outlined"
                  density="compact"
                  label="Task Type"
                  :rules="[v => !!v || 'Task type is required']"
                ></v-select>

                <v-textarea
                  class="pb-2"
                  variant="outlined"
                  density="compact"
                  v-model="newTask.description"
                  label="Task Description"
                  :rules="[v => !!v || 'Description is required']"
                ></v-textarea>

                <v-text-field
                  variant="outlined"
                  density="compact"
                  v-model="newTask.action_Date"
                  label="Due Date"
                  type="date"
                ></v-text-field>
              </v-card>
            </v-stepper-window-item>

            <!-- Parent Task Step -->
            <v-stepper-window-item :value="1">
              <v-card class="ma-0 pa-0">
                <v-col cols="12" class="px-0 pt-2 ma-0">
                  <v-text-field
                    v-model="searchTask"
                    prepend-inner-icon="mdi-magnify"
                    label="Find Parent Task"
                    clearable
                    density="compact"
                    variant="outlined"
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="text-center" v-if="isTasksLoading">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    size="40"
                  ></v-progress-circular>
                  <p class="mt-2">Getting Tasks</p>
                </v-col>

                <template v-else>
                  <v-col cols="12" class="pa-0 ma-0">
                    <v-treeview
                      :items="departmentItemsTasks"
                      :search="searchTask"
                      :custom-filter="filterFn"
                      item-title="name"
                      item-value="id"
                      return-object
                      open-all
                      select-strategy="single-independent"
                      selectable
                      activatable
                      v-model:selected="selectedTask"
                      @update:active="handleSelectTask"
                      class="border-sm rounded scrollable-treeview"
                    ></v-treeview>

                    <!-- Alert indicating the selected task -->
                    <v-alert
                      v-if="selectedTask && selectedTask.id"
                      type="success"
                      text
                      variant="tonal"
                      border="left"
                      class="mt-2"
                    >
                      Selected Task: {{ selectedTask.name }}
                    </v-alert>

                    <v-alert
                      v-else
                      type="info"
                      text
                      variant="tonal"
                      color="grey lighten-1"
                      border="left"
                      class="mt-2"
                    >
                      No task selected.
                    </v-alert>
                  </v-col>
                </template>
              </v-card>
            </v-stepper-window-item>

            <!-- Employees Step -->
            <v-stepper-window-item :value="2">
              <v-card>
                <v-row class="pa-0 ma-0">
                  <v-col cols="12" class="px-0 pt-2 ma-0">
                    <v-text-field
                      v-model="searchEmployee"
                      prepend-inner-icon="mdi-magnify"
                      label="Find Employee"
                      clearable
                      density="compact"
                      variant="outlined"
                      hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="text-center" v-if="isEmployeesLoading">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      size="40"
                    ></v-progress-circular>
                    <p class="mt-2">Getting Employees</p>
                  </v-col>

                  <template v-else>
                    <v-col cols="12" class="pa-0 ma-0">
                      <v-treeview
                        :items="departmentItemsEmployees"
                        :search="searchEmployee"
                        :custom-filter="filterFn"
                        item-title="name"
                        item-value="id"
                        return-object
                        selectable
                        open-all
                        @update:selected="handleSelectEmployee"
                        class="border-sm rounded scrollable-treeview"
                      ></v-treeview>

                      <v-alert
                        v-if="employeeFilterHasData === false"
                        type="info"
                        text
                        color="grey lighten-1"
                        border="left"
                      >
                        No employees found.
                      </v-alert>
                    </v-col>
                  </template>

                  <v-col cols="12" v-if="displayedEmployees.length !== 0">
                    <div class="py-2 font-weight-bold">Selected Employees</div>
                    <v-divider class="pt-2"></v-divider>

                    <v-scroll-y-transition group hide-on-leave>
                      <v-chip
                        v-for="(employee) in displayedEmployees"
                        :key="employee.id"
                        :text="employee.name"
                        class="ma-1"
                        color="primary"
                        prepend-icon="mdi-account"
                        size="small"
                      ></v-chip>
                    </v-scroll-y-transition>

                    <v-chip
                      v-if="additionalEmployeesCount > 0"
                      class="ma-1"
                      color="grey"
                      size="small"
                    >
                      and {{ additionalEmployeesCount }} more employees
                    </v-chip>
                  </v-col>
                </v-row>
              </v-card>
            </v-stepper-window-item>
          </v-stepper-window>
        </v-stepper>
      </v-form>
    </template>

    <!-- Footer with dynamic button for Next/Add Task -->
    <template v-slot:footer>
      <v-btn variant="text" @click="lastStep" :disabled="step < 1">Back</v-btn>
      <v-btn
        v-if="step === stepItems.length - 1"
        color="primary"
        append-icon="mdi-plus"
        variant="tonal"
        :loading="isAddBtnLoading"
        :disabled="isAddBtnDisabled"
        @click="addTask"
      >
        Create Task
      </v-btn>
      <v-btn
        v-else
        color="primary"
        append-icon="mdi-arrow-right"
        variant="tonal"
        @click="nextStep"
      >
        Next Step
      </v-btn>
    </template>
  </DialogBase>
</template>

<script>
import api from '@/services/api';
import DialogBase from './DialogBase.vue';

export default {
  name: 'AddTaskDialog',
  components: {
    DialogBase,
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAddBtnLoading: false,
      valid: true,
      step: 0,
      newTask: {
        type_ID: null,
        description: '',
        action_Date: null,
      },
      taskTypes: [],
      // Search inputs for each step
      searchTask: '',
      searchEmployee: '',
      // Step 2 data
      tasks: [],
      selectedTask: null,
      departmentItemsTasks: [], 
      isTasksLoading: false,
      taskFilterHasData: true,
      // Step 3 data
      employees: [],
      selectedEmployeeIds: [],
      selectedEmployees: [],
      departmentItemsEmployees: [
        { id: 'departments', name: 'All Departments', children: [] },
      ],
      isEmployeesLoading: false,
      employeeFilterHasData: true,
      stepItems: ['Task Details', 'Related Tasks', 'Employees'],
    };
  },
  computed: {
    isAddBtnDisabled() {
      return !this.newTask.type_ID || this.newTask.description.length < 3;
    },
    dialogOpen: {
      get() {
        return this.isDialogOpen;
      },
      set(value) {
        this.$emit('update:isDialogOpen', value);
      },
    },
    displayedEmployees() {
      return this.selectedEmployees.slice(0, 5);
    },
    additionalEmployeesCount() {
      return this.selectedEmployees.length - 5;
    },
  },
  watch: {
    step(newStep) {
      if (newStep === 1) {
        // Step 2: Load tasks
        this.getTasks();
      } else if (newStep === 2) {
        // Step 3: Load employees
        this.getEmployees();
      }
    },
  },
  async mounted() {
    await this.getTaskTypes();
  },
  methods: {
    async getTasks() {
      if (this.tasks && this.tasks.length) return;
      this.isTasksLoading = true;
      try {
        const response = await api.get(`/UserTask/GetUserTaskNodes`);
        if (response.status === 200) {
          this.tasks = response.data;
          this.organizeTasksByType();
        } else {
          console.log('Internal error');
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isTasksLoading = false;
      }
    },
    organizeTasksByType() {
      const types = this.tasks.reduce((acc, task) => {
        const typeName = task.type || 'Unknown Type';
        const typeId = task.type_ID || 'unknown';

        if (!acc[typeId]) {
          acc[typeId] = {
            id: `type_${typeId}`,
            name: typeName,
            children: [],
          };
        }

        acc[typeId].children.push({
          id: task.user_Task_ID,
          name: task.description || 'No Description',
        });

        return acc;
      }, 
      {});

      this.departmentItemsTasks = Object.values(types);
    },
    handleSelectTask() {
      
    },
    findTaskById(id, nodes = this.departmentItemsTasks) {
      for (const node of nodes) {
        if (node.id === id) {
          return node;
        }
        if (node.children) {
          const found = this.findTaskById(id, node.children);
          if (found) return found;
        }
      }
      return null;
    },
    async getEmployees() {
      if (this.employees.length) return;
      this.isEmployeesLoading = true;
      try {
        const response = await api.get(`/Employee/GetEmployees`);
        if (response.status === 200) {
          this.employees = response.data;
          this.organizeEmployeesByDepartment();
        } else {
          console.log('Internal error');
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isEmployeesLoading = false;
      }
    },
    async getTaskTypes() {
      try {
        const response = await api.get(`/TaskType/GetTaskTypes`);
        if (response.status === 200) {
          this.taskTypes = response.data;
        } else {
          console.log('Internal error');
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isEmployeesLoading = false;
      }
    },
    organizeEmployeesByDepartment() {
      const departments = this.employees.reduce((acc, employee) => {
        const departmentName = employee.department || 'Unknown';
        if (!acc[departmentName]) {
          acc[departmentName] = {
            id:
              employee.department_ID ||
              `no-department-${employee.employee_ID}`,
            name: departmentName,
            children: [],
          };
        }
        acc[departmentName].children.push({
          id: employee.employee_ID,
          name: employee.full_Name,
        });
        return acc;
      }, {});

      this.departmentItemsEmployees[0].children = Object.values(departments);
    },
    handleSelectEmployee(selectedIds) {
      this.selectedEmployees = selectedIds
        .map((id) => {
          const employee = this.findEmployeeById(id);
          return employee ? { id: employee.employee_ID, name: employee.full_Name } : null;
        })
        .filter((emp) => emp);
    },
    findEmployeeById(id) {
      return this.employees.find((employee) => employee.employee_ID === id);
    },
    filterFn(value, search) {
      if (!value || !search) return false;
      return value.toString().toLowerCase().includes(search.toLowerCase());
    },
    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.newTask.description = '';
      this.newTask.type_ID = null;
      this.newTask.action_Date = null;
      this.selectedEmployees = [];
      this.selectedTask = null;
      this.searchTask = '';
      this.searchEmployee = '';
      this.step = 0;
    },
    closeDialog() {
      this.dialogOpen = false;
      this.resetForm();
    },
    nextStep() {
      if (this.step < this.stepItems.length - 1) {
        this.step++;
      }
    },
    lastStep() {
      if (this.step > 0) {
        this.step--;
      }
    },
    async addTask() {
      this.isAddBtnLoading = true;
      const contactTask = {
        Type_ID: this.newTask.type_ID,
        Description: this.newTask.description,
        Action_Date: this.newTask.action_Date,
        Parent_Task_ID: this.selectedTask ? this.selectedTask.id : null,
        Assigned_Employee_IDs: this.selectedEmployees.map((emp) => emp.id),
      };
      try {
        await api.post('/UserTask/AddUserTask', contactTask);
        this.showSnackbar(
          'success',
          'mdi-check-circle',
          'Task Created!',
          'New task created successfully.'
        );
        this.$emit('refresh-tasks');
        this.closeDialog();
      } catch (error) {
        console.error(error);
        this.showSnackbar(
          'error',
          'mdi-alert-circle',
          'Error',
          'Failed to create task.'
        );
      } finally {
        this.isAddBtnLoading = false;
      }
    },
    showSnackbar(color, icon, title, description) {
      this.$emit('show-snackbar', {
        color,
        icon,
        title,
        description,
        timeout: 5000,
      });
    },
  },
};
</script>

<style scoped>
.scrollable-treeview {
  max-height: 300px;
  overflow-y: auto;
}

.v-stepper-header {
  box-shadow: 0px 0px 0px 0px !important;
  height: 50px;
  align-items: center;
  display: flex;
  position: relative;
  overflow-x: auto;
  justify-content: space-between;
  z-index: 1;
}
</style>
