<template>
  <div>
    <v-navigation-drawer
      class="no-scrollbar"
      :width="rail ? 80 : 320"
      v-model="drawer"
      :rail="rail"
      nav
      @update:model="onDrawerUpdate"
    >
      <v-list>
        <v-list-item
          :subtitle="userState.state.userProfile?.displayName"
          title="Global Precision Group"
          @click="toggleRail"
        >
          <template v-slot:prepend>
            <v-avatar color="purple">
              <span :class="rail ? 'text-caption' : 'text-h6'">{{ initials }}</span>
            </v-avatar>
          </template>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list density="compact" nav>
        <v-list-item value="dashboard" prepend-icon="mdi-chart-areaspline" @click="handleClick('/views/dashboard')">
          Dashboard
        </v-list-item>
        <v-list-item value="my-tasks" prepend-icon="mdi-bell-check-outline" @click="handleClick('/views/my-tasks')">
          <template v-slot:append>
            <v-badge
              :color="badgeColor"
              :content="badgeContent"
              class="animated-badge"
              :icon="badgeContent < 1 ? 'mdi-check' : ''"
              inline
            >
            </v-badge>
          </template>
          My Tasks
        </v-list-item>
        <v-list-item value="employees" prepend-icon="mdi-account-search-outline" @click="handleClick('/views/employees')">
          Employees
        </v-list-item>
        <v-list-item value="contacts" prepend-icon="mdi-card-account-details-outline" @click="handleClick('/views/contacts')">
          Contacts
        </v-list-item>
        <v-list-item value="task-management" prepend-icon="mdi-calendar-check" @click="handleClick('/views/task-management')">
          Task Management
        </v-list-item>
        <v-list-item value="task-hierarchy" prepend-icon="mdi-file-tree-outline" @click="handleClick('/views/task-hierarchy')">
          Task Hierarchy
        </v-list-item>
        <v-list-item value="calendar" prepend-icon="mdi-calendar-month-outline" @click="handleClick('/views/calendar')">
          Calendar
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-list density="compact" nav>
          <v-list-item value="settings" v-if="rail" @click="handleClick('/views/settings')">
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item>
          <v-btn v-else @click="handleClick('/views/settings')" prepend-icon="mdi-cog-outline" variant="text" block>
            Settings
          </v-btn>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-bottom-navigation v-if="!drawer" grow>
      <v-btn value="dashboard" @click="handleClick('/views/dashboard')" icon="mdi-chart-areaspline"></v-btn>
      <v-btn value="my-tasks" @click="handleClick('/views/my-tasks')" icon="mdi-bell-check-outline"></v-btn>
      <v-btn value="contacts" @click="handleClick('/views/contacts')" icon="mdi-card-account-details-outline"></v-btn>
      <v-btn value="employees" @click="handleClick('/views/employees')" icon="mdi-account-search-outline"></v-btn>
      <v-btn value="task-management" @click="handleClick('/views/task-management')" icon="mdi-calendar-check"></v-btn>
      <!--<v-btn value="settings" @click="handleClick('/views/settings')" icon="mdi-cog-outline"></v-btn>-->
    </v-bottom-navigation>
  </div>
</template>

<script>
import { useUserState } from '@/services/userStore';

export default {
  name: 'NavigationPanel',
  props: {
    uncompletedTaskCount: Number,
  },
  data() {
    return {
      drawer: true,
      rail: false,
      activeItem: null,
      userState: useUserState(),
    };
  },
  computed: {
    initials() {
      if (this.userState.state.userProfile && this.userState.state.userProfile.displayName) {
        const nameParts = this.userState.state.userProfile.displayName.split(' ');
        let initials = nameParts[0][0];
        if (nameParts.length > 1) {
          initials += nameParts[nameParts.length - 1][0];
        }
        return initials;
      }
      return '';
    },
    badgeContent() {
      return this.uncompletedTaskCount > 0 ? this.uncompletedTaskCount : '';
    },
    badgeColor() {
      return this.uncompletedTaskCount > 0 ? 'purple' : 'green';
    },
  },
  watch: {
    uncompletedTaskCount(newCount, oldCount) {
      if (newCount !== oldCount) {
        this.animateBadge();
      }
    },
  },
  methods: {
    handleClick(route) {
      this.$router.push(route);
    },
    toggleRail() {
      this.rail = !this.rail;
    },
    animateBadge() {
      const badge = document.querySelector('.animated-badge');
      if (badge) {
        badge.classList.remove('badge-bounce');
        // Trigger reflow to restart the animation
        void badge.offsetWidth;
        badge.classList.add('badge-bounce');
      }
    },
  },
};
</script>

<style scoped>
.animated-badge {
  transition: transform 0.4s ease;
}

.badge-bounce {
  animation: bounce 0.3s ease;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
</style>
