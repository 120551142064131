<template>
  <view-container :viewHeader="'Fanuc Ladder Processor'">
    <v-container fill-height class="d-flex align-center justify-center">
      <v-sheet
        class="d-flex align-center justify-center flex-wrap text-center mx-auto px-4"
        elevation="3"
        max-width="800"
        width="100%"
        rounded
      >
        <div>
          <h2 class="mt-8 mb-1 text-h4 font-weight-medium">Fanuc Ladder Processor</h2>

          <div class="text-body-2 font-weight-medium mb-2">
            Select a file to begin
          </div>

          <v-file-input
            class="mt-8"
            v-model="files"
            prepend-inner-icon="mdi-file-outline"
            :prepend-icon="false"
            :show-size="1000"
            color="deep-purple-accent-4"
            label="Select File"
            density="comfortable"
            placeholder="Select your file"
            variant="outlined"
            counter
            single
            @change="validateFile"
            @click:clear="processingResult = null"
          >
            <template v-slot:selection="{ fileNames }">
              <template v-for="(fileName, index) in fileNames" :key="fileName">
                <v-chip
                  v-if="index < 2"
                  class="me-2"
                  color="deep-purple-accent-4"
                  size="small"
                  label
                >
                  {{ fileName }}
                </v-chip>
                <span
                  v-else-if="index === 2"
                  class="text-overline text-grey-darken-3 mx-2"
                >
                  +{{ files.length - 2 }} File(s)
                </span>
              </template>
            </template>
          </v-file-input>

          <v-btn
            class="mt-6 mb-8"
            :loading="isProcessing"
            color="deep-purple-accent-4"
            variant="tonal"
            @click="processFanucFile"
            :disabled="!valid || files.length === 0"
          >
            <v-icon icon="mdi-text-box-check-outline" class="mr-2" size="1em"></v-icon>
            Process File
          </v-btn>

          <div v-if="processingResult && files.length > 0" class="mt-6 text-body-2 font-weight-medium mb-1">
            <v-row class="pb-1 justify-space-between align-center">
              <v-col class="d-flex align-start justify-start">
                Processing Results
              </v-col>
              <v-col class="d-flex justify-end" cols="auto">
                <v-tooltip location="start" text="Copy Full Output">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      class="mr-1"
                      @click="copyOutput"
                      variant="tonal"
                      color="deep-purple-accent-4"
                      size="x-small"
                      icon="mdi-clipboard-outline"
                      v-bind="props"
                    ></v-btn>
                  </template>
                </v-tooltip>

                <v-tooltip location="top" text="Smart Filter">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      :loading="isFiltering"
                      @click="smartFilter"
                      variant="tonal"
                      color="deep-purple-accent-4"
                      size="x-small"
                      icon="mdi-creation"
                      v-bind="props"
                    ></v-btn>
                  </template>
                </v-tooltip>
              </v-col>
            </v-row>
          </div>

          <v-sheet
            rounded="rounded"
            class="mb-6 text-start"
            v-if="processingResult && files.length > 0"
            max-width="600"
            min-height="120"
            border
          >
            <div class="pa-1">{{ processingResult }}</div>
          </v-sheet>
        </div>
      </v-sheet>
    </v-container>
  </view-container>
</template>

<script>
import api from "@/services/api";
import ViewContainer from "../layouts/ViewContainer.vue";

export default {
  name: "FanucLadderProcessor",
  components:{
    ViewContainer,
  },
  data() {
    return {
      valid: false,
      isProcessing: false,
      originalText: null, 
      isFiltering: false,
      isFiltered: false,
      files: [],
      processingResult: null,
    };
  },
  methods: {
    validateFile() {
      this.valid = this.files && this.files.length === 1;
    },
    async copyOutput() {
      await navigator.clipboard.writeText(this.processingResult);
    },
    async smartFilter() {
      if (!this.processingResult) return;

      this.isFiltering = true;
      await new Promise((resolve) => setTimeout(resolve, 500));

      // If already filtered, revert to the original text
      if (this.isFiltered) {
        this.processingResult = this.originalText;
        this.isFiltered = false;
      } else {
        // Save the original text if filtering for the first time
        this.originalText = this.processingResult;

        // Define a general pattern to match any text before the colon and extract the value after the colon
        const pattern = /: ([\w*]+)/g;

        // Extract matches
        const matches = [];
        let match;
        let counter = 1;

        // Iterate through all matches and format them
        while ((match = pattern.exec(this.processingResult)) !== null) {
          matches.push(`[Value ${counter} = ${match[1]}]`);
          counter++;
        }

        // Join the matches with new line characters to display each value on a new line
        this.processingResult = matches.length > 0 ? matches.join("\n") : "No matching values found.";
        this.isFiltered = true;
      }

      this.isFiltering = false;
    },
    async processFanucFile() {
      if (!this.valid || this.files.length === 0) return;

      this.isProcessing = true;
      this.processingResult = null;

      try {
        const formData = new FormData();
        formData.append("file", this.files[0]);

        const response = await api.post("/Processing/ProcessFanucFile", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        await new Promise((resolve) => setTimeout(resolve, 700));

        if (response.status === 200) {
          this.processingResult = response.data;
        } else {
          console.error("Failed to process the file.");
        }
      } catch (error) {
        console.error("Error processing file:", error);
      } finally {
        this.isProcessing = false;
      }
    },
  },
};
</script>

<style scoped>
.v-card-title {
  font-weight: bold;
}
.v-divider {
  margin: 0.5rem 0;
}
.v-card-text {
  padding-top: 0;
}
.status-btn {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 135px;
  height: 100px;
  text-transform: none;
}
.text-start {
  text-align: start;
}
</style>
