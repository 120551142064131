<template>
  <div>
    <ViewContainer>
      <v-row dense>
        <v-col class="pa-0" cols="12" md="8">
          <!-- v-card group for task statuses -->
          <task-status-cluster></task-status-cluster> 

          <!-- completion event chart -->
          <v-card border="gray thin" elevation="0" class="ma-1 pa-3" height="790">
            <p>Completion Events</p>
            <v-divider></v-divider>
            <user-event-completion-chart></user-event-completion-chart>
          </v-card>
        </v-col>

        <!-- timeline on the right column taking up both rows -->
        <v-col class="pa-0" cols="12" md="4">
          <v-card border="gray thin" elevation="0" class="ma-1 pa-3" height="900">
            <p>My Event Timeline</p>
            <v-divider></v-divider>
            <timeline-chart></timeline-chart>
          </v-card>
        </v-col>
      </v-row>  
    </ViewContainer>
  </div>
</template>

<script>
import ViewContainer from '../layouts/ViewContainer.vue';
import UserEventCompletionChart from '../../components/charts/UserEventCompletionChart';
import TimelineChart from '@/components/charts/TimelineChart.vue';
import TaskStatusCluster from '@/components/presenters/TaskStatusCluster.vue';

export default {
  name: 'DashboardView',
  components: {
    ViewContainer,
    TaskStatusCluster,
    UserEventCompletionChart,
    TimelineChart,
  },
  data() {
    return {
      refreshInterval: null, // To hold the interval ID
    };
  },
  mounted() {
    
  },
  methods: {

  },
};
</script>

<style scoped>
.v-card-title {
  font-weight: bold;
}
.v-divider {
  margin: 0.5rem 0;
}
.v-card-text {
  padding-top: 0;
}
.status-btn {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 135px;
  height: 100px;
  text-transform: none;
}
</style>
